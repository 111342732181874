export const data = [
    {
        language: 'dotnet',
        rows: [
            {
                title: 'Senior DotNet',
                subtitle: 'Developer',
                required: 'Exp 2 - 6 Yrs',
                image: '../../assets/careers/dotnet.png',
                posted: '08/02/2024',
                res: [
                    "Should collaborate with stakeholders to achieve common goals",
                    "Should gather requirements and define project scope",
                    "Should Mentor and guide team members",
                    "Champion accountability and excellence"
                ]
            }
        ]
    },
        {
        language: 'DBMS',
        rows: [
            {
                title: 'Software',
                subtitle: 'Project Administrator',
                required: 'Exp 4+ Yrs',
                image: '../../assets/careers/DBMS.png',
                posted: '27/03/2024',
                res: [
                    "Optimize database performance and manage data effectively",     
                    "Generate reports and analyze data to extract insights",
                    "Collaborate with teams to integrate databases with cloud platforms",
                    "Ensure data security protocols are implemented"
                ]
            }
        ]
    },
    {
        language: 'JBDE',
        rows: [
            {
                title: 'Junior Business ',
                subtitle: 'Development Executive',
                required: 'Exp 0 - 2 Yrs',
                image: '../../assets/careers/JBDE.png.png',
                posted: '10/04/2024',
                res: [
                    "Identify and pursue new business opportunities, ",
                    "Build client relationships, assist in sales strategy development, ",
                    "Deliver presentations, and monitor sales performance.",
                    
                ],
                qualifications:[
                    'MBA or related degree.',
                    'Strong communication, and independence in dynamic settings.',
                    'MS Office proficiency; CRM knowledge a plus.'

                ]
            }
        ]
    },
    {
        language: 'SSE',
        rows: [
            {
                title: 'Senior Software',
                subtitle: 'Engineer',
                required: 'Exp 3 - 5 Yrs',
                image: '../../assets/careers/SSE.png',
                posted: '30/06/2024',
                res: [
                    "Develop, test and maintain top-notch software across the full stack.",
                    "Experience with Agile methodology ,Cloud, DevOps and Microservices a plus.",
                    "Bridge the tech gap by clearly explaining ideas to stakeholders.",
                    "Thrive in challenging projects with your problem-solving versatility.",
                    "Coach colleagues on technical and communication skills.",
                    "Foster a positive and inclusive work environment.",
                    "Possess strong understanding of application design.",
                ]
            }
        ]
    },
    {
        language: 'TL',
        rows: [
            {
                title: 'Technical',
                subtitle: 'Lead',
                required: 'Exp 5 - 7 Yrs',
                image: '../../assets/careers/TL.png',
                posted: '15/07/2024',
                res: [
                    "Lead Projects: Oversee the technical execution of development projects.",
                    "Team Collaboration: Work with cross-functional teams to design and deploy applications.",
                    "Technical Expertise: Leverage NodeJS, ReactJS, ReactNative, MySQL,HTML/CSS, AWS, and CI/CD.",
                    "Quality Assurance: Ensure robust and reliable software solutions.",
                    "Mentorship: Guide junior team members and foster a collaborative environment.",
                    "Problem Solving: Address technical challenges effectively.",
                ]
            }
        ]   
    },
    {
        language: 'PY',
        rows: [
            {
                title: 'Python',
                subtitle: 'Developer',
                required: 'Exp 1 - 2 Yrs',
                image: '../../assets/careers/PY.png',
                posted: '25/07/2024',
                res: [
                    "Develop and maintain Python/Django applications.",
                    "Integrate front-end elements with HTML, CSS, JavaScript, and server-side logic.",
                    "Optimize application speed and scalability; debug and write robust code.",
                    "Participate in code reviews and stay updated on industry trends.",
                    "Bachelor’s degree in Computer Science, Information Technology, or a related field.",
                    "Strong problem-solving skills and attention to detail.",
                    "Excellent communication and teamwork abilities.",
                ]
            }
        ]
    },
    // {
    //     language: 'angular',
    //     rows: [
    //         {
    //             title: 'Angular',
    //             subtitle: 'Developer',
    //             required: 'Exp 1 - 3 Yrs',
    //             image: '../../assets/careers/Angular.svg',
    //             posted: '05/02/2023',
    //             res: [
    //                 'Should Have An Expertise In Core Java & Springboot',
    //                 'Required In Oops Concepts And Springboot Hands On',
    //                 'Required In Web Services-Restful',
    //                 'Required In Application Servers Like Tomcat And Weblogic',
    //                 'Good Knowledge Of Implementing Jms Messaging Services',
    //                 'Good Knowledge Of Html5, Css And Javascript',
    //                 'Contribute To The Development Life Cycle, Requirements Definition/Analysis, And Feasibility Analysis',
    //                 'Decompose Design Into Development Plan Tasks And Provide an Accurate Estimation Of Effort'
    //             ]
    //         }
    //     ]
    // },
    // {
    //     language: 'java',
    //     rows: [
    //         {
    //             title: 'Java',
    //             subtitle: 'Developer',
    //             required: 'Exp 1 - 3 Yrs',
    //             posted: '05/02/2023',
    //             res: [
    //                 'Should Have An Expertise In Core Java & Springboot',
    //                 'Required In Oops Concepts And Springboot Hands On',
    //                 'Required In Web Services-Restful',
    //                 'Required In Application Servers Like Tomcat And Weblogic',
    //                 'Good Knowledge Of Implementing Jms Messaging Services',
    //                 'Good Knowledge Of Html5, Css And Javascript',
    //                 'Contribute To The Development Life Cycle, Requirements Definition/Analysis, And Feasibility Analysis',
    //                 'Decompose Design Into Development Plan Tasks And Provide an Accurate Estimation Of Effort'
    //             ]
    //         }
    //     ]
    // },
    // {
    //     language: 'node',
    //     rows: [
    //         {
    //             title: 'Node Js',
    //             subtitle: 'Developer',
    //             required: 'Exp 1 - 3 Yrs',
    //             posted: '05/02/2023',
    //             res: [
    //                 'Should Have An Expertise In Core Java & Springboot',
    //                 'Required In Oops Concepts And Springboot Hands On',
    //                 'Required In Web Services-Restful',
    //                 'Required In Application Servers Like Tomcat And Weblogic',
    //                 'Good Knowledge Of Implementing Jms Messaging Services',
    //                 'Good Knowledge Of Html5, Css And Javascript',
    //                 'Contribute To The Development Life Cycle, Requirements Definition/Analysis, And Feasibility Analysis',
    //                 'Decompose Design Into Development Plan Tasks And Provide an Accurate Estimation Of Effort'
    //             ]
    //         }
    //     ]
    // },
    // {
    //     language: 'python',
    //     rows: [
    //         {
    //             title: 'Python',
    //             subtitle: 'Developer',
    //             required: 'Exp 1 - 3 Yrs',
    //             posted: '05/02/2023',
    //             res: [
    //                 'Should Have An Expertise In Core Java & Springboot',
    //                 'Required In Oops Concepts And Springboot Hands On',
    //                 'Required In Web Services-Restful',
    //                 'Required In Application Servers Like Tomcat And Weblogic',
    //                 'Good Knowledge Of Implementing Jms Messaging Services',
    //                 'Good Knowledge Of Html5, Css And Javascript',
    //                 'Contribute To The Development Life Cycle, Requirements Definition/Analysis, And Feasibility Analysis',
    //                 'Decompose Design Into Development Plan Tasks And Provide an Accurate Estimation Of Effort'
    //             ]
    //         }
    //     ]
    // },
    // {
    //     language: 'react',
    //     rows: [
    //         {
    //             title: 'React Js',
    //             subtitle: 'Developer',
    //             required: 'Exp 1 - 3 Yrs',
    //             posted: '05/02/2023',
    //             res: [
    //                 'Should Have An Expertise In Core Java & Springboot',
    //                 'Required In Oops Concepts And Springboot Hands On',
    //                 'Required In Web Services-Restful',
    //                 'Required In Application Servers Like Tomcat And Weblogic',
    //                 'Good Knowledge Of Implementing Jms Messaging Services',
    //                 'Good Knowledge Of Html5, Css And Javascript',
    //                 'Contribute To The Development Life Cycle, Requirements Definition/Analysis, And Feasibility Analysis',
    //                 'Decompose Design Into Development Plan Tasks And Provide an Accurate Estimation Of Effort'
    //             ]
    //         }
    //     ]
    // },
    // {
    //     language: 'selenium',
    //     rows: [
    //         {
    //             title: 'Quality',
    //             subtitle: 'Analyst',
    //             required: 'Exp 1 - 3 Yrs',
    //             posted: '05/02/2023',
    //             res: [
    //                 'Should Have An Expertise In Core Java & Springboot',
    //                 'Required In Oops Concepts And Springboot Hands On',
    //                 'Required In Web Services-Restful',
    //                 'Required In Application Servers Like Tomcat And Weblogic',
    //                 'Good Knowledge Of Implementing Jms Messaging Services',
    //                 'Good Knowledge Of Html5, Css And Javascript',
    //                 'Contribute To The Development Life Cycle, Requirements Definition/Analysis, And Feasibility Analysis',
    //                 'Decompose Design Into Development Plan Tasks And Provide an Accurate Estimation Of Effort'
    //             ]
    //         }
    //     ]
    // }
];
