import React from 'react';
import { useState } from 'react';
import { Typography, Grid, Paper, Container, Box, Button } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import Header from '../../components/Header/index';
import Footer from '../../components/Footer';
import { navigate } from 'gatsby';
import icon from '../../images/footer.svg';
import backIcon from '../../images/backicon.svg';
import colorIcon from '../../images/backColor.svg';
import Popup from '../../components/CareerPopup';
import Form from '../CareersForm';
import dotnetimg from '../../assets/careers/dotnet.png'
import DBMSimg from '../../assets/careers/DBMS.png'
import JBDEimg from '../../assets/careers/JBDE.png'
import SSEimg from '../../assets/careers/SSE.png'
import TLimg from '../../assets/careers/TL.png'
import PYimg from '../../assets/careers/PY.png'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ButtonBase from '@mui/material/ButtonBase';
import { useLocation } from '@reach/router';
import { data } from '../../components/datas/CareersCard';
import { makeStyles } from '@mui/styles';
import Scroller from '../../components/Scroller';
const useStyles = makeStyles({
    desc_paper: {
        position: 'relative',
        margin: '1rem',
        boxShadow:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important'
    },
    content: {
        fontFamily: 'Poppins !important',
        letterSpacing: '0.38px !important',
        fontSize: '18px !important',
        color: '#343333',
        fontWeight: '800 !important'
    },
    para: {
        fontSize: '10px',
        fontFamily: 'Poppins',
        marginBottom: '0.5rem',
        //
        color: '#9A9A9A',
        fontWeight: '400 !important',
        letterSpacing: '0.18px',
        lineHeight: '13px !important'
    }
});
const CareerLanguage = (props) => {
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(location.state?.id ? location.state?.id : 0);

    const tabTitle = [
        {
            title: 'Senior DotNet Developer',
            language: 'dotnet'
        },
        {
            title: 'Software Project Administrator',
            language: 'DBMS'
        },
        {
            title: 'Junior Business Development Executive',
            language: 'JBDE'
        },
        {
            title: 'Senior Software Engineer',
            language: 'SSE'
        },
        {
            title: 'Technical Lead',
            language: 'TL'
        },
        {
            title: 'Python Developer',
            language: 'PY'
        },
        // {
        //     title: 'Angular Developer',
        //     language: 'angular'
        // },
        // {
        //     title: 'Java Developer',
        //     language: 'java'
        // },
        // {
        //     title: 'Node JS Developer',
        //     language: 'node'
        // },
        // {
        //     title: 'Python Developer',
        //     language: 'python'
        // },
        // {
        //     title: 'React Js Developer',
        //     language: 'react'
        // },
        // {
        //     title: 'Quality Analyst',
        //     language: 'selenium'
        // }
    ];

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const classes = useStyles();
    const [back, setBack] = useState(false);

    const [openPopup, setOpenPopup] = useState(false);
    const [desgination, setDesgination] = useState('');
    const [language, setLanguage] = useState();

    //  useEffect(()=>{
    const [result] = data.filter((ele) => ele.language === props?.params?.CareerLanguage);
    //  },[language])

    return (
        <>
            <Header />
            <Paper
                variant="Customer_Banner"
                id="back-to-top-anchor"
                sx={{
                    position: 'relative'
                }}
            >
                <Container>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label="ant example"
                        variant="scrollable"
                        justifyContent="center"
                        allowScrollButtonsMobile
                        sx={{
                            position: 'sticky',
                            textTransform: 'none',
                            marginRight: '-15px',
                            '& .MuiTabs-indicator': {
                                display: 'none'
                            },

                            '& .MuiTab-root ': {
                                color: '#343333 !important'
                            },
                            '& .Mui-selected': {
                                color: '#A6B50C !important',
                                fontWeight: '700 !important'
                            },
                            '& .MuiTabs-flexContainer': {
                                display: 'flex',
                                justifyContent: 'space-between'
                            }
                        }}
                    >
                        {tabTitle.map((item) => {
                            return (
                                <Tab
                                    label={item.title}
                                    onClick={() => {
                                        navigate(`/career/${item.language}`);
                                    }}
                                    sx={{
                                        '&.MuiTab-root ': {
                                            color: '#343333',
                                            marginLeft: '-15px',
                                            textTransform: 'initial  !important',
                                            fontFamily: 'Poppins',
                                            opacity: '1 !important',
                                            fontSize: '15px !important',
                                            fontWeight: '500 ',
                                            letterSpacing: '0.5px',
                                            justifyContent: 'center'
                                        }
                                    }}
                                />
                            );
                        })}
                    </Tabs>
                </Container>
            </Paper>
            <Paper variant="careers_cards">
                <Container>
                    <Grid
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            position: 'relative',
                            zIndex: '1000',
                            marginTop: '2.5rem',
                            '@media (min-width: 320px) and (max-width:375px)': {
                                marginLeft: '30px'
                            },
                            '@media (min-width: 375px) and (max-width:425px)': {
                                marginLeft: '30px'
                            },
                            '@media (min-width: 425px) and (max-width:700px)': {
                                marginLeft: '30px'
                            },
                            '@media (min-width: 768px) and (max-width:1024px)': {
                                marginLeft: '30px'
                            }
                        }}
                    >
                        <img
                            src={back ? colorIcon : backIcon}
                            alt="mask"
                            onMouseEnter={() => setBack(true)}
                            onMouseOut={() => setBack(false)}
                            onClick={() => {
                                // setOpen(true);
                                navigate('/career');
                            }}
                            style={{
                                cursor: 'pointer',
                                position: 'fixed',
                                marginTop: '8px',
                                marginLeft: '-40px'
                            }}
                        />
                    </Grid>
                </Container>
            </Paper>

            <Container>
                <Box sx={{ mt: '-6rem' }}>
                    <Grid>
                        <Container>
                            {result &&
                                result.rows.map((item, index) => {
                                    return (
                                        <Paper
                                            className={classes.desc_paper}
                                            onClick={() => {
                                                setDesgination(
                                                    result.rows[index].title +
                                                    ' ' +
                                                    result.rows[index].subtitle
                                                );
                                                setOpenPopup(true);
                                            }}
                                        >
                                            <Grid item xs={12} md={12}>
                                                <StaticImage
                                                    src="../../images/mask.svg"
                                                    style={{
                                                        // float: "right",
                                                        position: 'absolute',
                                                        right: '0',
                                                        maxHeight: '200px',
                                                        maxWidth: '200px'
                                                    }}
                                                    placeholder="none"
                                                />
                                            </Grid>
                                            <Grid container>
                                                <Grid item>
                                                    <ButtonBase
                                                        sx={{
                                                            width: 200,
                                                            height: '100%',
                                                            backgroundColor: '#D6D5E1'
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                props?.params?.CareerLanguage ===
                                                                    'dotnet'
                                                                    ? dotnetimg
                                                                    : props?.params
                                                                        ?.CareerLanguage ===
                                                                        'DBMS'
                                                                        ? DBMSimg
                                                                        : props?.params
                                                                            ?.CareerLanguage ===
                                                                            'JBDE'
                                                                            ? JBDEimg :
                                                                            props?.params
                                                                                ?.CareerLanguage ===
                                                                                'SSE'
                                                                                ? SSEimg :
                                                                                props?.params
                                                                                ?.CareerLanguage ===
                                                                                'TL'
                                                                                ? TLimg :  props?.params
                                                                                ?.CareerLanguage ===
                                                                                'PY'
                                                                                ? PYimg : ''


                                                            }
                                                            // src={
                                                            //     props?.params?.CareerLanguage ===
                                                            //         'angular'
                                                            //         ? angularimg
                                                            //         : props?.params
                                                            //             ?.CareerLanguage ===
                                                            //             'java'
                                                            //             ? java
                                                            //             : props?.params
                                                            //                 ?.CareerLanguage ===
                                                            //                 'python'
                                                            //                 ? pythonimg
                                                            //                 : props?.params
                                                            //                     ?.CareerLanguage ===
                                                            //                     'node'
                                                            //                     ? nodeimg
                                                            //                     : props?.params
                                                            //                         ?.CareerLanguage ===
                                                            //                         'react'
                                                            //                         ? reactimg
                                                            //                         : seleniumimg
                                                            // }
                                                            style={{
                                                                // padding:'60px',
                                                                width: '50%',
                                                                // height: '50%',
                                                                textAlign: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            alt=""
                                                        />
                                                    </ButtonBase>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm
                                                    container
                                                    sx={{
                                                        '&:hover': {
                                                            boxShadow:
                                                                '  55px 55px 15px -50px rgb(0 0 0 / 20%)',
                                                            cursor: 'pointer'
                                                        }
                                                    }}
                                                >
                                                    <Grid item xs container direction="column">
                                                        <Grid
                                                            item
                                                            xs
                                                            sx={{
                                                                marginTop: '0.5rem',
                                                                marginBottom: '0.5rem',
                                                                marginLeft: '15px'
                                                            }}
                                                        >
                                                            <Typography className={classes.content}>
                                                                {item.title}{' '}
                                                                <span style={{ color: '#BECE20' }}>
                                                                    {item.subtitle}
                                                                    {/* Developer */}
                                                                </span>
                                                                <br />
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Poppins',
                                                                    letterSpacing: '0.21px',
                                                                    fontSize: '12px',
                                                                    color: '#757575',
                                                                    margin: '5px 1px'
                                                                }}
                                                            >
                                                                {' '}
                                                                {item.required}
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '500 !important',
                                                                    fontSize: '18px',
                                                                    color: '#333333',
                                                                    paddingBottom: '0.5rem',
                                                                    paddingTop: '0.5rem',
                                                                    letterSpacing: '0.3px'
                                                                }}
                                                            >
                                                                Responsibilities
                                                            </Typography>
                                                            {item.res.map((ele) => (
                                                                <Typography
                                                                    className={classes.para}
                                                                    sx={{
                                                                        fontSize: '10px',
                                                                        fontFamily: 'Poppins',
                                                                        marginBottom: '0.5rem',
                                                                        //
                                                                        color: '#9A9A9A',
                                                                        fontWeight:
                                                                            '400 !important',
                                                                        letterSpacing: '0.18px',
                                                                        lineHeight: '8px',
                                                                        '@media (min-width: 320px) and (max-width:375px)':
                                                                        {
                                                                            fontSize: '10px',
                                                                            lineHeight: '15px'
                                                                        },
                                                                        '@media (min-width: 375px) and (max-width:500px)':
                                                                        {
                                                                            fontSize: '10px',
                                                                            lineHeight: '15px'
                                                                        },
                                                                        '@media (min-width: 425px) and (max-width:768px)':
                                                                        {
                                                                            fontSize: '10px',
                                                                            lineHeight: '15px'
                                                                        }
                                                                    }}
                                                                >
                                                                    {ele}
                                                                </Typography>
                                                            ))}

                                                            {props?.params?.CareerLanguage === 'JBDE' &&
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: '500 !important',
                                                                        fontSize: '18px',
                                                                        color: '#333333',
                                                                        paddingBottom: '0.5rem',
                                                                        paddingTop: '0.5rem',
                                                                        letterSpacing: '0.3px'
                                                                    }}
                                                                >
                                                                    Qualification
                                                                </Typography>}

                                                            {props?.params?.CareerLanguage === 'JBDE' && item.qualifications.map((ele) => (
                                                                <Typography
                                                                    className={classes.para}
                                                                    sx={{
                                                                        fontSize: '10px',
                                                                        fontFamily: 'Poppins',
                                                                        marginBottom: '0.5rem',
                                                                        //
                                                                        color: '#9A9A9A',
                                                                        fontWeight:
                                                                            '400 !important',
                                                                        letterSpacing: '0.18px',
                                                                        lineHeight: '8px',
                                                                        '@media (min-width: 320px) and (max-width:375px)':
                                                                        {
                                                                            fontSize: '10px',
                                                                            lineHeight: '15px'
                                                                        },
                                                                        '@media (min-width: 375px) and (max-width:500px)':
                                                                        {
                                                                            fontSize: '10px',
                                                                            lineHeight: '15px'
                                                                        },
                                                                        '@media (min-width: 425px) and (max-width:768px)':
                                                                        {
                                                                            fontSize: '10px',
                                                                            lineHeight: '15px'
                                                                        }
                                                                    }}
                                                                >
                                                                    {ele}
                                                                </Typography>
                                                            ))}
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                sx={{
                                                                    color: '#9B9B9B',
                                                                    fontFamily:
                                                                        'Poppins !important',
                                                                    fontWeight: '400 !important',
                                                                    fontSize: '11px',
                                                                    float: 'right',
                                                                    marginTop: '-1.5rem',
                                                                    marginRight: '-9rem',
                                                                    '@media (min-width: 320px) and (max-width:375px)':
                                                                    {
                                                                        marginTop: '0.5rem',
                                                                        marginRight: '5rem'
                                                                    },
                                                                    '@media (min-width: 375px) and (max-width:425px)':
                                                                    {
                                                                        marginTop:
                                                                            '0.5rem !important',
                                                                        marginRight: '9rem'
                                                                    },
                                                                    '@media (min-width: 425px) and (max-width:768px)':
                                                                    {
                                                                        marginTop:
                                                                            '0.5rem !important',
                                                                        marginRight: '11.6rem'
                                                                    },
                                                                    '@media (min-width: 768px) and (max-width:1024px)':
                                                                    {
                                                                        marginTop:
                                                                            '0.5rem !important',
                                                                        marginRight: '18.6rem'
                                                                    },
                                                                    '@media (min-width: 1024px) and (max-width:1440px)':
                                                                    {
                                                                        marginRight: '-9rem'
                                                                    }
                                                                }}
                                                            >
                                                                Posted On: {item.posted}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            variant="carrer_button"
                                                            sx={{ margin: '15px' }}
                                                            onClick={() => {
                                                                setDesgination(
                                                                    result.rows[index].title +
                                                                    ' ' +
                                                                    result.rows[index].subtitle
                                                                );
                                                                setOpenPopup(true);
                                                            }}
                                                        >
                                                            Apply With Us
                                                            <img
                                                                src={icon}
                                                                alt="mask"
                                                                style={{
                                                                    // width: '40px',
                                                                    height: '30px',
                                                                    marginTop: '3px'
                                                                }}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    );
                                })}
                        </Container>
                        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
                            <Form desginationName={desgination} setOpenPopup={setOpenPopup} />
                        </Popup>
                    </Grid>
                    {/* <Scroller /> */}
                </Box>
            </Container>

            <br />
            <Box
                sx={{
                    '@media (min-width: 1400px) and (max-width:2560px)': {
                        bottom: 0,
                        position: 'absolute',
                        width: '100%'
                    }
                }}
            >
                <Footer />
            </Box>
            <Box
                sx={{ float: 'right', right: 0 }}
            >
                <Scroller />
            </Box>
        </>
    );
};

export default CareerLanguage;
