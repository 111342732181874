import React from 'react';

import { Fab, useScrollTrigger, Zoom } from '@mui/material';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

function ScrollTop(props) {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor'
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation">
                {children}
            </div>
        </Zoom>
    );
}

const BackToTop = (props) => {
    return (
        <ScrollTop>
            <Fab
                size="medium"
                aria-label="scroll back to top"
                classes="fab"
                style={{
                    backgroundColor: '#BECE20'
                }}
            >
                <ArrowDropUp
                    sx={{
                        color: 'white'
                    }}
                />
            </Fab>
        </ScrollTop>
    );
};

export default BackToTop;
